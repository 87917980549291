import './navbar.css'

function Navbar() {
    return (
        <div className="navbar">
            <span>NEWTRUN</span>
        </div>
    );
}

export default Navbar;